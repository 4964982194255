<template>
  <div>
   <div class="zx-icon">
     <template v-if="language=='zh'">
       <img src="@/assets/zxzx.png" alt="" @click="toiconClick">
     </template>
     <template v-if="language=='TW'">
       <img src="@/assets/zxzxTW.png" alt="" @click="toiconClick">
     </template>
     <template v-if="language=='En'">
       <img src="@/assets/zxzxEn.png" alt="" @click="toiconClick" >
     </template>
   </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      language:'zh',
    }
  },
  created() {
    this.language=localStorage.getItem('language')
  },
  methods:{
    toiconClick(){
        this.$emit('iconchange')
    }
 }
}
</script>

<style>
.zx-icon img{
    width: 76PX;
    height: 127PX;
    position: fixed;
    bottom: 30vh;
    z-index: 2000;
    right: 20PX;
}
</style>