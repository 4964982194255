<template>
  <div>
    <div class="HKtop-mian ">
      <div>
        <template v-if="language=='zh'">
          <myimage :url='url'></myimage>
        </template>
        <template v-if="language=='TW'">
          <myimage :url='urlTW'></myimage>
        </template>
        <template v-if="language=='En'">
          <myimage :url='urlEn'></myimage>
        </template>
      </div>
      <div class="hidden-xs-only">
         <div
          :class="screenWidth>=992?'HKmain-service hidden-xs-only hidden-sm-and-down':'HKmain-servicesn hidden-xs-only hidden-md-and-up' ">
        <div class="HK-dry">
          <div class="china-text">{{$t('hkgoods.首页')}} > {{$t('hkgoods.港漂干货')}}</div>
          <div class="english-text">{{$t('hkgoods.Portdriftdrycargo')}}</div>
          <div :class="index==HKproblemList.length-1?'HK-top ':'HK-top HK-topborder'"
               v-for="(item,index) in HKproblemList" :key='index'  @click="toHKgoodsDetail(item.content,item.abroadId)">
            <div class="HK-toptitle">{{ item.title }}</div>
            <div class="HK-topcontent">
              <div>
                <img src="../../assets/image/HKgoods/hot.png" alt="">{{ item.viewsCount||0 }}
              </div>
              <div>
                <img src="../../assets/image/HKgoods/time.png" alt="">{{ item.createDate.slice(5,10) }}
              </div>
            </div>
          </div>
          <!-- <div class="Hkpagination">
            <MyPagination
                :query='query'
                :total="1000">
            </MyPagination>
          </div> -->
        </div>
        <div class="HKmain-service-bot" :style="[{'height':companyheight}]">
          <div class="HKenglish-yellow">{{$t('hkgoods.APPLICATIONPROCESS')}}</div>
          <div class="HKservice-con">
            <div class="HKservicetop">{{$t('hkgoods.支持')}}</div>
            <div class="HKservicebot"></div>
            <div class="all-service-company" >
              <div v-for="(item,index) in agencList" :key='index'  >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    <!-- 移动端 -->
      <div class="hidden-sm-and-up">
        <div class="HKmain-service-sm">
          <div class="HK-dry-sm">
            <div class="china-text-phone">{{$t('hkgoods.首页')}} > {{$t('hkgoods.港漂干货')}}</div>
            <div class="english-text-phone">{{$t('hkgoods.Portdriftdrycargo')}}</div>
            <div :class="index==HKproblemList.length-1?'HK-top-sm ':'HK-top-sm HK-topborder'"
               v-for="(item,index) in HKproblemList" :key='index' @click="toHKgoodsDetail(item.content,item.abroadId)">
              <div class="HK-toptitle-sm">{{ item.title }}</div>
              <div class="HK-topcontent-sm">
                <div>
                  <img src="../../assets/image/HKgoods/hot.png" alt="">{{ item.viewsCount||0 }}
                </div>
                <div>
                  <img src="../../assets/image/HKgoods/time.png" alt="">{{ item.createDate.slice(5,10) }}
                </div>
              </div>
            </div>
            <!-- <div class="Hkpagination" style="margin-top:20PX">
              <MyPagination
                  :query='query'
                  :total="100">
              </MyPagination>
            </div> -->
          </div>
         <div class="main-service-bot-sm" :style="[{'height':companyheight2}]">
          <div class="english-yellow-sm">{{$t('hkgoods.APPLICATIONPROCESS')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('hkgoods.支持')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-company-sm" >
              <div v-for="(item,index) in agencList" :key='index'>
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
    <Mydialog ref="icon" @submit="submitchange"></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import "element-ui/lib/theme-chalk/display.css";
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import {agencList, hongKongStudyAbroadList, addMessageRecord, addViewsCountHongKongStudyAbroad} from '@/api/index.js'

export default {
  components: {
    myimage,
    Mydialog,
    Myicon
  },
  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10
      },
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      HKproblemList: [],
      url:require('@/assets/image/ghgh.png'),
      urlTW:require('@/assets/image/ghghTW.png'),
      urlEn:require('@/assets/image/ghghEn.png'),
      baseUrl:'',
      agencList:[],
      language:'zh'
    }
  },
  computed:{
    companyheight(){
      if(this.agencList.length>0){
     return  Math.ceil(this.agencList.length/4)*110+250 +'PX'
      }else{
         return 300+'PX'
      }
    },
     companyheight2(){
      if(this.agencList.length>0){
        return (Math.ceil(this.agencList.length/Math.floor( 335/100))*110+40)+'PX'
      }else{
         return 100+'PX'
      }
    },
    companymargin(){
      if(this.agencList.length>0){
        return Math.floor(( this.screenWidth*0.9-Math.floor(this.screenWidth*0.9/224)*224)/(Math.floor(this.screenWidth*0.9/224)-1))+'PX'
      }else{
         return 0+'PX'
      }
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
                 location.reload();
       
      })();
      
    };
  },
  created(){
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
    this._agencList()
    this._hongKongStudyAbroadList()
  },
  methods:{
    //在线咨询（港漂干货）
    submitchange(e){
        e.category=8
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    //获取港漂干货列表
    _hongKongStudyAbroadList(){
      hongKongStudyAbroadList({
        category:3,
        type:''
      }).then(res=>{
        this.HKproblemList=res.data
        this.HKproblemList = this.HKproblemList.reverse()
      })
    },
    //获取团队
      _agencList(){
        agencList().then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.thumbnail=this.baseUrl+item.thumbnail
          })
          this.agencList=res.data
        })
      },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    toHKgoodsDetail(content,abroadId){
      if(!content)return
      addViewsCountHongKongStudyAbroad({
        abroadId:abroadId
      }).then(res=>{
        console.log('增加香港留学浏览量',res)
        window.location.href=content
      })
      // window.location.href=content
      // 没详情要跳超链接
      //  this.$router.push({
      //     path: '/Overseas-Study',
      //   });
    }
  }
}
</script>
<style scoped>
</style>
<style>
.HKtop-mian {
  width: 100vw;
}

.Hkpagination {
  margin: 0 auto;
  text-align: center;
}

.HKmain-service {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}

.HKmain-service-sm {
  width:335PX;
  margin: 0 auto;
}

.HKmain-service > div, .HKmain-service-sm > div {
  position: relative;
  top: 0;
}

.HK-dry {
  /* height: 794px; */
  padding: 70px 0 70px 0;
  text-align: left;
}

.china-text {
  font-size: 20PX;
  font-weight: 600;
}

.english-text {
  padding-bottom: 24px;
  font-size: 28PX;
  border-bottom: 4PX solid #FADBBA;
  color: #999999;
  margin-bottom: 50px;
}

.HK-top {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.HK-toptitle {
  font-size: 20PX;
  margin-bottom: 15px;
  font-weight: bold;
}

.HK-topcontent {
  font-size: 16PX;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.HK-topcontent img {
  width: 24PX;
  height: 24PX;
  vertical-align: -5px;
  margin-right: 6px;
}

.HK-topborder {
  border-bottom: 4px solid #F7F7F7;
}


/* .HKmain-service-bot {
  height: 606px;
} */
.HKmain-service-bot {
  padding: 0 0;
}

.all-service-company{
  width:1176PX;
  padding: 30PX 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20PX auto 0 auto;
}
.all-service-company>div{
  width: 280PX;
  height: 100PX;
  margin-bottom: 30PX;
}
.all-service-company>div img{
  width: 280PX!important;
  height: 100PX;
}
.HKenglish-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
}

.HKservice-con {
  width: 100%;
  position: absolute;
  top: 0px;
}

.HKservicetop {
  width: 220px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-bottom: 6px solid #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.HKall-service {
  width: 100%;
 height: 392px;
  margin-top: 50px;
}
.HKall-service img{
  width: 100%;
 height: 392px;
}

/* 移动端 */
.HK-dry-sm {
  /* height: 794px; */
  padding: 14PX 0!important;
  text-align: left;
}
.china-text-phone {
  font-size: 18PX;
  font-weight: 600;
}

.english-text-phone {
  padding-bottom: 24px;
  font-size: 16PX;
  border-bottom: 2PX solid #FADBBA;
  color: #999999;
  /* margin-bottom: 50px; */
}

.HK-top-sm {
  padding: 16PX 0;
  display: flex;
  flex-direction: column;
}

.HK-toptitle-sm {
  font-size: 16PX;
  margin-bottom: 15px;
  font-weight: bold;
}

.HK-topcontent-sm {
  font-size: 14PX;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.HK-topcontent-sm img {
  width: 20PX;
  height: 20PX;
  vertical-align: -5PX;
  margin-right: 6PX;
}
.main-service-bot-sm{
  height: 450PX;
}
.english-yellow-sm {
  color: #FE992B;
  font-size: 20PX;
  opacity: 0.3;
  font-weight: bold;
}

.service-con-sm {
  width: 100%;
  position: absolute;
  top: 10PX;
}

.servicetop-sm {
  /* width: 150px; */
  font-size: 20PX;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-radius: 3PX;
  margin: 0 auto;
}

.servicebot-sm {
  width: 69PX;
  height: 6PX;
  background: #FE992B;
  border-radius: 3PX;
  margin: 0 auto;
}


.companypng-sm {
  width: 100%;
  height: 362PX;
  margin-top: 16PX;
}

.all-service-company-sm{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20PX;
}
.all-service-company-sm>div,.all-service-company-sm>div img{
  width:110PX;
  height: 60PX;
  margin-bottom: 20PX;
}
</style>