<template>
  <el-dialog :close-on-click-modal='false' class="dialog1" :visible.sync="dialogVisible" :width="screenWidth>500?'450px':'344px'">
    <div class="top" slot="title">
      <div class="dialog-one">
        <img src="@/assets/image/logo.png" alt="">
        <div style="margin-right: 13px;margin-left: 8px">{{$t('dialog.汇生汇')}}</div>
        <div>
          <div>({{$t('dialog.香港')}}) {{$t('dialog.文化科技有限公司')}}</div>
        <div>({{$t('dialog.深圳')}}) {{$t('dialog.文化科技有限公司')}}</div>
        </div>
        </div>
       <!-- <div>{{$t('dialog.汇生汇')}} <span>({{$t('dialog.香港')}}) {{$t('dialog.文化科技有限公司')}}</span></div>
      <div class="text">({{$t('dialog.深圳')}}) {{$t('dialog.文化科技有限公司')}}</div> -->
      <div class="text">{{$store.state.info.hotline}}</div>
      <div class="text">{{$store.state.info.phone}}</div>
    </div>
    <div class="content3">
      <div>{{$t('dialog.留言内容')}}</div>
      <!-- <textarea class="input2" rows="8"></textarea>
        <div><input /></div> -->
      <el-input
          type="textarea"
          :rows="4"
          :placeholder="$t('dialog.请输入')"
          v-model="query.content"
      >
      </el-input>
      <div>{{$t('dialog.姓名')}}</div>
      <el-input v-model="query.name" :placeholder="$t('dialog.请输入')"></el-input>
      <div>{{$t('dialog.电话')}}</div>
      <el-input v-model="query.phone"  :placeholder="$t('dialog.请输入')"></el-input>
      <div>{{$t('dialog.地址')}}</div>
      <el-input v-model="query.address" :placeholder="$t('dialog.请输入')"></el-input>
      <div>{{$t('dialog.微信')}}</div>
      <el-input v-model="query.wxAccount" :placeholder="$t('dialog.请输入')"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">{{$t('dialog.提交')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "lianxiwomen",
  data() {
    return {
      dialogVisible: false,
      form: {},
      query:{
        recordType:1,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//	必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'',//	必选	String	性别(recordType=2/3)
      },
      screenWidth: document.body.clientWidth,
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    submit(){
      if(!this.query.phone){
        this.$message.error(this.$t('dialog.请填写手机号'))
        return
      }
      this.$emit('sumbit',this.query)
      this.query={
        recordType:1,//	必选	String	1:在线留言2:学生公寓3:香港留学
        category:'',//	必选	String	来源位置1:首页2:首页香港留学3:(recordType=1)
        name:'',//	必选	String	姓名
        phone:'',//	必选	String	手机号
        wxAccount:'',//	必选	String	微信账号
        address:'',//	必选	String	地址
        content:'',//	必选	String	留言内容(recordType=2/3申请内容)
        gender:'',//	必选	String	性别(recordType=2/3)
      },
      this.dialogVisible = false
    }
  },
};
</script>

<style lang="scss">
.dialog-one {
display: flex;
align-items: center;
}
.dialog1 {
  .el-button:hover {
    border-color: #fe982bc2;
  }

  .el-button--primary:hover {
    background-color: #fe982bc2;
    border-color: #fe982bc2;
  }

  .el-button--primary {
    color: #fff;
    background-color: #fe992b;
    border-color: #fe992b;
  }

  .el-input__inner:focus {
    border-color: #e3e3e2;
    outline: 0;
  }
   .el-input__inner{
    padding: 20PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner{
    padding: 10PX 10PX;
    font-size: 12PX;
  }
  .el-textarea__inner:focus {
    outline: 0;
    border-color: #e3e3e2;
  }

  .el-dialog__body {
    text-align: left;
    font-size: 14PX;
  }

  .el-dialog {
    border-radius: 12PX;
  }

  .el-dialog__header {
    // text-align: left;
    background-color: #fe992b;
    border-radius: 12PX 12PX 0 0;
    padding: 10PX 20PX;
  }
.el-dialog__footer {
    padding:0 30PX 40PX 0;
  }
  .el-dialog__close {
    color: #fff;
    font-size: 16PX;
    margin: 10PX 10PX 0 0;
  }

  .content3 {
    padding: 0 20PX;
    > div {
      margin-bottom: 10PX;
    }

    .input2 {
      width: 304PX;
      border-radius: 4PX;
      border: none;
      padding: 15PX;
      background-color: #fff;
      border: 1PX solid #e3e3e2;
      font-size: 16PX;
      outline: none;
    }
  }

  .top {
    text-align: left;
    color: #fff;
    font-size: 14PX;

    .text {
      margin-left: 56PX;
    }

    > div {
      span {
        margin-left: 10PX;
      }

      margin-bottom: 4PX;
    }
  }
  .dialog-footer{
    // margin-bottom: 20PX;
    button{
      // line-height: 20PX;
      padding: 10PX 10PX;
      font-size: 12PX;
      // width: 60PX;
      text-align: center;
    }
  }
}
</style>